@import "assets/scss/material-kit-pro-react";
@import "~leaflet/dist/leaflet.css"; // sass
@import "~react-leaflet-markercluster/dist/styles.min.css"; // sass

// @import "../../node_modules/slick-carousel/slick/slick-theme.css";
// @import "../../node_modules/slick-carousel/slick/slick.css";

body,
html {
  font-family: "Roboto", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  font-size: 18px;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
}

form {
  width: 100%;
}

@media only screen and (min-width: 512px) {
  .container {
    max-width: 90%;
    margin: auto;
  }
}

.container {
  width: 100%;
  margin: auto;
}

.content {
  padding: 16px;
}

.leaflet-geosearch-button form {
  min-width: 350px;
}

.leaflet-popup-content {
  margin: 0;
}
